import ScrollMagic from "scrollmagic"; 

interface ScrollAnimParams {
    element: string;
    trigger: number;
    duration: string;
    target?: string;
    offset?: number;
    toggle?: boolean; 
    pin?: string;
}

class ScrollAnimation { 

    private ScrollController;
    
    constructor() {
        this.ScrollController = new ScrollMagic.Controller();  
    }

    // Method to Handle all Scroll Animation 
    ScrollAnim(json: ScrollAnimParams) {
        const Scrolls = new ScrollMagic.Scene({
            triggerElement: json.element,
            triggerHook: json.trigger,
            duration: json.duration 
        }).addTo(this.ScrollController);
     
        if (json.toggle) { 
            const elements = document.querySelectorAll(`${json.element} .inview`); 
            Scrolls.on('enter', function (e) {
                for (var i = 0; i < elements.length; ++i) {
                    var setAnimation = (elements[i] as HTMLElement).dataset.ease || 'fadeInUp';
                    (elements[i] as HTMLElement).classList.add(setAnimation);
                }
            });  
        } 
        if (json.pin) {
            Scrolls.setPin(json.element);
        }
        if (json.offset) {
            Scrolls.offset(json.offset);
        }  
    } 
}
  
export default ScrollAnimation;
  