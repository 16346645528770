import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import { LanguageProvider } from './LanguageContext'; 
import HomePage from "./pages/HomePage"; 
import languageConfig  from './json/languages.json';
import './styles/App.scss'; 

function App() {  
return (
    <Router basename={process.env.PUBLIC_URL}>   
        <LanguageProvider>
            <Routes> 
            {languageConfig && Object.entries(languageConfig).map(([, lang], index) => ( 
                <Route key={index} path={lang['path']} element={<HomePage />} />
            ))} 
                <Route path="*" element={<HomePage />} />  
            </Routes>
        </LanguageProvider>
    </Router>
    );
}

export default App;
