import { Component } from 'react'; 

interface SocialProps {
    data: { 
        social: SocialMessage[];
        isReady: boolean;
    };
}

interface SocialMessage {
    link: string;
    name: string;
    class: string;
}

export class Social extends Component<SocialProps> {   

    render() {
        const { social, isReady } = this.props.data; 
        return (
            <>
                {isReady && social && social.map((social: SocialMessage, index: number) => ( 
                    <a key={index} href={social.link} target="_blank" rel="noopener noreferrer" aria-label={social.name}><i className={social.class} /></a> 
                ))}
            </>
        )
    }
}