import { Component } from 'react';
import DoorLogo from "../assets/tots_door.png"; 

export class PreLoader extends Component {
    render() {
        return (
            <div className="inner-box">
                <img src={DoorLogo} className="img-fluid" width="300" height="291" alt="Tales of the Shire" />
            </div>
        )
    }
}