export const wordTrim = (sentence: string, maxLength: number) => {
    if (sentence.length >= maxLength) {
        return sentence.substring(0, maxLength) + "...";
    }  
    return sentence;
};

export const sanitizeToSlug = (title: string) => {
    // Remove leading and trailing whitespace
    let sanitizedTitle = title.trim(); 
    // Replace spaces with hyphens
    sanitizedTitle = sanitizedTitle.replace(/\s+/g, "-"); 
    // Convert to lowercase
    sanitizedTitle = sanitizedTitle.toLowerCase(); 
    // Remove special characters (optional)
    sanitizedTitle = sanitizedTitle.replace(/[^\w-]+/g, ""); 
    return sanitizedTitle;
  }

export const ScrollToView = (sectionId: string, e?: Event) => {
    if(e) e.preventDefault();  
    let cleanSectionId = sectionId.toLowerCase();  
    const section = document.getElementById(cleanSectionId); 
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
    return false;
};