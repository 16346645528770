import axios from 'axios';

class WPClass {
  private apiUrl: string;

  constructor() {  
    this.apiUrl = `${process.env.REACT_APP_CMS_HOST}/wp-json/`;
  }

  async makeRequest(endpoint: string, single: boolean = true) {
    try {
      const response = await axios.get(`${this.apiUrl}${endpoint}`);
      // Return the first item or an empty object if response data is empty
      return (single === true) ? response.data[0] ?? {} : response.data ?? {}; 
    } catch (error) {
      console.error(error); // Log any errors that occur during the request
      return {}; // Return an empty object in case of an error
    }
  }

  async makeRequestPromise(endpoints: {}) {
    try {   
      const axiosRequests = Object.entries(endpoints).map(([key, url]) =>
        axios.get(`${this.apiUrl}${url}`).then(response => ({ [key]: response.data }))
      ); 
      const responses = await Promise.all(axiosRequests);
      const responseData = responses.reduce((acc, curr) => ({ ...acc, ...curr }), {}); 
      return responseData ?? {};
      // Return the first item or an empty object if response data is empty
    } catch (error) {
      console.error(error); // Log any errors that occur during the request
      return {}; // Return an empty object in case of an error
    }
  }
}
  

export default WPClass;